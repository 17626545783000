<template>
    <div>
        <h2>信息提交</h2>
        <van-form class="popup-form" @submit="onSubmit">
            <van-field label="应付金额: " v-model="bankInfo.earnest_money" readonly />
            <van-field label="开户名称: " v-model="bankInfo.bank_name" readonly />
            <van-field label="开户银行：" v-model="bankInfo.bank" readonly />
            <van-field label="银行账号：" v-model="bankInfo.bank_number" readonly />
            <van-field name="uploader" label="转账记录：" :rules="[{ required: true, message: '上传照片！' }]">
                 <template #input>
                    <UploadImg
                        ref="business_license"
                        :images="business_license"
                        :maxCount="1"
                    ></UploadImg>
                    </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import UploadImg from '@/components/UploadImg'
import { micro_post } from "../../../Http/api.js";
    export default {
        components: {
            UploadImg,
        },
        data() {
            return {
                fileList1:[],
                business_license: [],
                pic:'123',
                bankInfo:{},
                valObj:{},
            }
        },
        methods: {
            onSubmit() {
                 const data = {
                    url: "index.php/BidManagement/bidSignOrder",
                    params: { shopId:this.$route.query.shopId,type:this.$route.query.type,act_id:this.valObj.id,pay_image: this.$refs.business_license.imagesUrlList[0]},
                    success: (res) => {
                        this.$toast.fail(res.msg)
                        this.$emit('application')
                    },
                   
                };
                micro_post(data,this);
            },
            getInfo(val){
                this.valObj = val
                  this.$refs.business_license.imagesUrlList = []
                this.$refs.business_license.images = []
                const data = {
                    url: "index.php/BidManagement/bidSignOrderDetail",
                    params: { id:val.id },
                    success: (res) => {
               
                        this.bankInfo = res.data
                    },
                   
                };
                micro_post(data,this);
            },
            afterDelete(file){

            },
            uploadershang(file){

            }
        },
    }
</script>

<style lang="scss" scoped>
h2{
    text-align: center;
}
</style>