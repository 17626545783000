import axios from 'axios'

import { setCookie, getCookie, deleteCookie } from '@/utils/env'
import { Toast, Notify } from 'vant';
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { isShouQuans,NO1ShouQuans } from '../utils/impower.js'
// console.log(Router,router)
//这里是设置请求超时时间 时间为5秒
axios.defaults.timeout = 120000;


//封装get请求
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//封装post请求
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url,
            params
        ).then(res => {
            if (res.data.code === 0) {
                resolve(res)

            }  else {
                reject(res)
                Toast(res.data.msg);

            }
        }).catch(err => {
            reject(err.data)
        })
    })
}
//封装post请求
export function is_post(url,params){
    return new Promise((resolve,reject)=>{
        axios.post(url,params).then(res=>{
            let result = {}
                if(res.data.code == -1 || res.data.code == 9){
                    result = res.data
                }else{
                    result = JSON.parse( encryptDecode(res.data))
                }
            if(result.code === 0) {
                resolve(result)
            }else if(result.code === -1) {
                deleteCookie('token')
                window.location.href = window.location.origin + '/'
                Toast({message:result.msg,duration: 3000});
            }else if(result.code === 9999){
                reject(result)
            }else{
                reject(result)
                Toast({message:result.msg,duration: 3000});
            }
        }).catch(err=>{
            reject(err.data)
            Toast(err.data);
        })
    })
}


//封装post请求
export function encrypt_post(url,params){
    return new Promise((resolve,reject)=>{
        axios.post(url,params).then(res=>{
            let result = {}
            if(res.data.code == -1 || res.data.code == 9){
                result = res.data
            }else{
                result = JSON.parse( encryptDecode(res.data))
            }
            resolve(result)
        }).catch(err=>{
            reject(err.data)
            Toast(err.data);
        })
    })
}




export async function micro_post(data,_this) {
    let url = data.url
    let params = data.params
    let code_fun = {}  //默认不存在指定的错误方法
    if(data.hasOwnProperty('code_fun')){
        code_fun=data.code_fun;
    }


    if (!data.hasOwnProperty('success')) {
        data.success = function (data) {
            
            Notify({
                message: data.msg,
                duration: 1000,
                type: "success"
            });
        }
    }
    let success = data.success
    if (!data.hasOwnProperty('err')) {
        data.err = function (data) {
            Notify({
                message: data,
                duration: 1000,
            });
        }
    }
    let errorCallFunction = data.err
    // axios.defaults.timeout = 30000;
     await axios.post(url, params).then(res => {
         if (typeof (res.data) === 'object') {
             if (res.data.code === 0) {
                 success(res.data)
                } else{
                    let code = String(res.data.code);
                    // if(((Object.keys(code_fun).length ===0) && !code_fun.hasOwnProperty('-1'))){
                  
                        if(code == '-1'){
                            window.localStorage.removeItem('openid')
                            NO1ShouQuans(_this)
                                   //重新授权
                            // _this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                        
                            //     if(res.data.code == 0){
                                    // let domain = res.data.data
                                   
                                    // let urls ="https://wechat.chexd.com/chaichejian/BiddingList.html?domain=" +domain + '&url=' +url + '&shopId=' + shopId + '&type=' +type + '&path=' + path +'&id=' + id
                                    
                                    //  _this.$http.post('/index.php/index/wechat.bid_wechat/redirect_uri',{url:window.location.href,shopId}).then(res=>{
                                    //    alert(22)
                                    //     if(res.data.code == 0) {
                                    // //         console.log(res)
                                    //     }else if(res.data.code == 2){
                                    //         window.location.href = res.data.data
                                    //     }else{
                                    //         // window.localStorage.setItem('isShouQuan',res.data.data.wechatFansInfo.openid)
                                    //         _this.$toast.fail(res.data.msg)
                                    //     }
                                    // })
                            //     }
                            // })
                        }

                        // window.location.href = 'http://www.baidu.com'
                    // }


                    if(code_fun.hasOwnProperty(code)){
                        code_fun[code](res.data)
                        return;
                    }
                errorCallFunction(res.data.msg)
            }
        } else {
            Notify({
                message: '请求失败,请联系管理员',
                duration: 2000,
            });
        }
    }).catch(err => {
        Notify({
            message: '请求失败,请联系管理员',
            duration: 2000,
        });
        console.log("发生异常:" + err)
    })

}